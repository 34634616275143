<template>
  <div>
    <titleBar
      :title="'Access'"
      :img="bot?.avatar"
      back="auto"
      :showBackArrowDesktop="true"
      subtitle=""
      :centered="false"
      theme="white"
      :showSignup="false"
      containerClass="donotuse"
      :showDesktop="true"
      :showAfterScroll="0"
      :scrollTopOnClick="true"
      :inline="false"
      actionButtonStyle="round"
      actionButtonRoundBg="transparent"
      iconPack="fas"
      :isChild="true"
    />

    <div class="sectionNA">
      <div class="containerNA NHAw800">
        <hr />
        <b-field horizontal label=" ">
          <h2 class="title is-2">
            <span>Access</span>
          </h2>
        </b-field>
        <!-- START-->
        <br />
        <b-field horizontal>
          <h3 class="title is-4"></h3>
        </b-field>
        <!-- Buefy Radios for "mode", with options for: demo, pro, unlimited-->

        <!-- Buefy Radios for "mode", with options for: demo, pro, unlimited-->
        <b-field horizontal label="Page visibility">
          <div>
            <b-radio v-model="bot.visibilityMode" native-value="public" type="is-info" :disabled="posting">
              Public (anyone can discover it)
            </b-radio>
            <br />
            <b-radio v-model="bot.visibilityMode" native-value="secret" type="is-info" :disabled="posting">
              Secret URL <span class="tag is-warning is-light">Premium</span>
            </b-radio>

            <br />
            <b-radio v-model="bot.visibilityMode" native-value="private" type="is-info" :disabled="posting">
              Private (only you)
            </b-radio>
            <!-- 
          <br />
          <b-radio v-model="bot.monetizationMode" native-value="pro" type="is-info" :disabled="posting"> Pro </b-radio>
          <br />
          <b-radio v-model="bot.monetizationMode" native-value="unlimited" type="is-info" :disabled="posting">
            Subscribers (paid members only)
            <span class="tag is-info is-light">Premium</span>
          </b-radio>
          <br />
          <b-radio v-model="bot.monetizationMode" native-value="unlimited" type="is-info" :disabled="posting">
            Unlimited <span class="tag is-info is-light">Premium</span>
          </b-radio>
           -->
          </div>
        </b-field>
        <!-- buefy switch , show in public listing -->

        <!--


          Subscribers (paid members only)
Unlimited

  Unlimited <span class="tag is-info is-light">Premium</span>


  Buefy Radios for "Pricing", with options for: community, paid, subscription
      -->
        <br />
        <br />
        <b-field horizontal label="   ">
          <h3 class="title is-4">API</h3>
        </b-field>
        <b-field horizontal label="API access">
          <div>
            <b-radio v-model="bot.apiAccess" native-value="off" type="is-info" :disabled="posting">
              No API access
            </b-radio>
            <br />
            <b-radio v-model="bot.apiAccess" native-value="secprivateret" type="is-info" :disabled="posting">
              Private (only your apps) <span class="tag is-warning is-light">Premium</span>
            </b-radio>

            <br />
            <b-radio v-model="bot.apiAccess" native-value="authenticated" type="is-info" :disabled="posting">
              Authenticated apps <span class="tag is-warning is-light">Premium</span>
            </b-radio>

            <br />
            <b-radio v-model="bot.apiAccess" native-value="embed" type="is-info" :disabled="posting">
              Selected websites can embed it <span class="tag is-warning is-light">Premium</span>
            </b-radio>

            <br />
            <b-radio v-model="bot.apiAccess" native-value="public" type="is-info" :disabled="posting">
              Full public access <span class="tag is-warning is-light">Premium</span>
            </b-radio>

            <!-- 
          <br />
          <b-radio v-model="bot.monetizationMode" native-value="pro" type="is-info" :disabled="posting"> Pro </b-radio>
          <br />
          <b-radio v-model="bot.monetizationMode" native-value="unlimited" type="is-info" :disabled="posting">
            Subscribers (paid members only)
            <span class="tag is-info is-light">Premium</span>
          </b-radio>
          <br />
          <b-radio v-model="bot.monetizationMode" native-value="unlimited" type="is-info" :disabled="posting">
            Unlimited <span class="tag is-info is-light">Premium</span>
          </b-radio>
           -->
          </div>
        </b-field>

        <!-- END     
    -->
        <b-field horizontal label="   ">
          <b-button type="is-info is-outlined" @click="save" :loading="posting" :disabled="posting"
            >Save changes</b-button
          >
        </b-field>

        <hr />
      </div>
    </div>
  </div>
</template>

<script>
//import Editor from "@/components/e/EditorV";

export default {
  //name: "readmeEditor",
  components: {
    //Editor,
  },
  props: {
    bot: {
      type: Object,
      default: null,
    },
    posting: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // readmeJson: defaultReadme, // "432432432",
    };
  },
  methods: {
    save() {
      this.$emit("save");
    },
  },
};
</script>

<style></style>
